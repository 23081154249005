/* eslint-disable max-len */
/* eslint-disable relay/graphql-syntax */
// eslint-disable-next-line no-unused-vars
import React, {useCallback, useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import Navbar from '../../components/Business/NavBar';
import SectionHeader from '../../components/SectionHeader';
import ValidationItem from '../../components/ValidationItem';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import Quote from '../../components/Quote';
import API from '../../helpers/api';
import DemoForm from '../../components/DemoForm';
import RedSpan from '../../components/RedSpan';

const Container = styled.div`
  background-color: white;
  padding: 90px 30px 40px 30px;
  display: flex;
  margin-top: 50px;
  gap: 60px;
  justify-content: center;

  @media (max-width: 1092px) {
    padding-top: 58px;
    flex-direction: column;
  }
`;

const ContactFormSection = styled.div`
  display: flex;
  flex: 1;
  max-width: 674px;
`;

const ValidationSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px 30px;
  max-width: 674px;

  @media (max-width: 1092px) {
    padding: 0px;
  }
`;

const ValidationItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-top: 40px;
`;

const contactFormStyle = {
  flex: 1,
};

const VALIDATION_ITEMS = [
  {
    title: 'Best user experience in class, ZERO interruptions',
    // eslint-disable-next-line prettier/prettier
    text: 'We believe professionals should be able to do their work without any interruptions. This is why we designed the most optimized user experience packaged in a slick mobile app.',
    Icon: ThumbUpAltIcon,
  },
  {
    title: 'Built with love',
    // eslint-disable-next-line prettier/prettier
    text: 'We love creating software products that make people lives easier. Enjoy premium customer support regardless of your pricing plan.',
    Icon: VolunteerActivismIcon,
  },
  {
    title: 'Across industries',
    text: 'Available for more than 30+ industries. Contact us and we will help you get started.',
    Icon: Diversity1Icon,
  },
];

const ContactUsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    // Register Facebook lead event
    if (typeof window !== 'undefined' && window.fbq) {
      window.fbq('track', 'Lead');
    }
  }, []);

  const handleSubmit = async (data, clearForm) => {
    setIsLoading(true);

    data.refId = localStorage.getItem('refId');
    data.source = 'site/demo';
    const body = JSON.stringify(data);

    try {
      await API.post('leads/partner', {method: 'POST', body});

      if (typeof window !== 'undefined') {
        if (window.fbq) {
          window.fbq('track', 'SubmitApplication', data);
        }

        const {email, name, phoneNumber, organizationName, industry, inquiryText} = data;
        const identityProperties = {
          $email: email,
          $first_name: name,
          $organization: organizationName,
        };

        window?.klaviyo?.identify(identityProperties, () => {
          window.klaviyo.push(['track', 'Completed demo form', {inquiryText, industry, phoneNumber, email}]);
        });
      }

      setIsSubmitted(true);
      clearForm();
    } catch (e) {
      console.log('error', e);
    }

    setIsLoading(false);
  };

  const {t} = useTranslation();

  const alignItems = isSubmitted ? 'center' : 'flex-start';

  return (
    <main>
      <Helmet>
        <title>CindyApp - Business</title>
      </Helmet>
      <>
        <Navbar />
      </>
      <Container>
        <ContactFormSection style={{alignItems}}>
          <DemoForm
            onSubmit={handleSubmit}
            containerStyle={contactFormStyle}
            formTitle={t(
              `Tell us a little about yourself and we'll connect you with an expert who can share more about the product and answer any questions you have.`,
            )}
            isLoading={isLoading}
            buttonTitle={t('SUBMIT')}
            successState={isSubmitted}
          />
        </ContactFormSection>
        <ValidationSection>
          <SectionHeader style={{color: 'black'}}>
            <Trans i18nKey="One solution for <1>all</1> of your existing schedule problems">
              One solution for <RedSpan>all</RedSpan> of your existing schedule problems
            </Trans>
          </SectionHeader>
          <ValidationItems>
            {VALIDATION_ITEMS.map((item) => (
              <ValidationItem {...item} />
            ))}
          </ValidationItems>
          <Quote
            text={
              <Trans>
                Our clients experience an average 
                <span style={{fontWeight: 'bold', textDecoration: 'underline'}}>increase of 34%</span> in the total
                number of appointments just 4 months after they start using CindyApp.
              </Trans>
            }
            subtitle={`${t('Nik Telkedzhiev')} | CEO | CindyApp`}
            style={{marginTop: 42}}
          />
        </ValidationSection>
      </Container>
    </main>
  );
};

export default ContactUsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
